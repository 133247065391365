import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import trackVisitor from "./utilities/tracker";
import moment from "moment";
import GameResult from "./gameResult";

function App() {
  const [data, setData] = useState([]);
  const currentTime = moment().format("HH:mm");
  const todayDate = moment(new Date()).format("lll");
  var currentDate = moment().tz("Asia/Kolkata").format("YYYY-MM-DD");
  const [datagame, setDataFor] = useState([]);
  let prevDate = moment(new Date())
    .tz("Asia/Kolkata")
    .subtract(1, "days")
    .format("YYYY-MM-DD");

  const scrollToChart = () => {
    const tableSection = document.getElementById("table-section");
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    trackVisitor();
  });

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  // changes according to timing
  useEffect(() => {
    if (data?.length > 0) {
      // Convert current time to a moment object for comparison
      const currentMoment = moment(currentTime, "HH:mm");

      // Process and filter the data
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;

        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });

      // Sort the processed data by open_time
      const sortedProcessedData = processedData.sort((a, b) => {
        return a.itemTime.diff(b.itemTime);
      });

      // Separate records into those with available results and those with "wait"
      const availableResults = sortedProcessedData.filter(
        (item) => item.isAvailable
      );
      const upcomingRecords = sortedProcessedData.filter(
        (item) => !item.isAvailable
      );

      // Determine the records to display
      let recordsToDisplay = [];

      if (availableResults.length > 0) {
        recordsToDisplay = [...availableResults];

        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }

      if (recordsToDisplay.length > 3) {
        recordsToDisplay = recordsToDisplay.slice(-3);
      }

      setDataFor(recordsToDisplay);

      // Debugging log
    }
  }, [data, currentTime]);
  return (
    <div>
      <div class="row navbaar m-0 w-100 text-center">
        <div class="col-6 nav-c p-1 border border-2 border-white text-white fw-bold">
          <a href="/"> HOME</a>
        </div>
        <div class="col-6 p-1 border border-2 border-white border-start-0 text-white fw-bold">
          <span onClick={scrollToChart}> CHART</span>
        </div>
      </div>

      <div class="text-center header p-4">
        <h1 className="fw-bold">SATTA LEAK</h1>
      </div>

      <marquee>
        ध्यान रहे :: हम यहाँ सट्टा नंबर की Guessing/भविष्यवाणी करके गेम बनाकर
        देते हैं । हमारा किसी भी कंपनी से कोई लेना देना नहीं है । आप अपनी
        समझदारी से पैसे का लेन-देन करें । सबके हित में जारी ।
      </marquee>

      <div className="leak-result text-center text-white fw-bold">
        SATTA LEAK ONLINE RESULT
      </div>

      <marquee className="fs-3 bg-white marquee">
        {" "}
        <font color="#A52A2A"> News</font> <font color="#ff7e00">Live</font>{" "}
        <font color="#03F">MORNING STAR</font> &nbsp;
        <font color="#00b63c;">»</font> &nbsp;
      </marquee>

      <div className="banner container-fluid text-center">
        <a href="/">WWW.SATTA-LEAK.CO :-</a>
        <h3 className="text-white mt-1">
          जी हाँ सबसे तेज़ और सही रिजल्ट् सिर्फ इसी साइट पे मिलेगा
        </h3>
        <h5 className="text-white">{todayDate} </h5>
        {datagame?.map((todayData, index) => (
          <div key={index} className="game">
            <h1 className="info text-info mb-0">{todayData?.gameName}</h1>{" "}
            <button type="button" className=" btn mb-5 ">
              <div className="blinking-text">{todayData?.result || ""}</div>
            </button>
          </div>
        ))}
      </div>
      <GameResult dayGameData={data} />
    </div>
  );
}

export default App;
