import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import GameResult from "./gameResult";
import Footer from "./footerDrop";
import Advertisement from "./advertisement";
import App from "./App";
import BottomSection from "./bottom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="container-fluid">
    <App />
    <Footer />
    <Advertisement />
    <BottomSection/>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
