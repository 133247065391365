function BottomSection() {
  return (
    <div className="bg-light text-center mt-3">
      <div className="bottom-section fw-bold pt-3 px-3">
        <h4>** DISCLAIMER **</h4>
        <p>
          www.satta-leak.com is a Non Commercial Website We Show Only News and
          Provide Entertainment. Viewing This WebSite Is On Your Own Risk.. All
          The information Shown On Website Is Sponsored And We Warn You That
          Satta in Your Country May be Banned or Illegal... We Are Not
          Responsible For Any Issues or Scam... We Respect All Country
          Rules/Laws... If You Not Agree/Satisfied With Our Site Disclaimer...
          Please Quit Our Site Right Now and Never Visit this Site Again.
          Thankyou. !! Satta Leak GROUP !!
        </p>
      </div>
      <p className="fw-bold rights m-0 p-1">
        © (2017-2024) Satta-leak.com All Rights Reserved.
      </p>
    </div>
  );
}

export default BottomSection;
